import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { FeeCalculator2 } from "./FeeCalculator2";

export const Estimator: React.FC = () => {
  const { activeFBO } = useActiveFBOs();

  return (
    <Stack direction="column" spacing={2} p={4}>
      <Typography variant="h6">Fee Calculator</Typography>
      <FeeCalculator2
        defaultMonthlyRate={activeFBO.nightly_base_rate * 30}
        defaultDailyRate={activeFBO.nightly_transient_rate}
      />
      {/* <FeeCalculator /> */}
    </Stack>
  );
};
