import InfoIcon from "@mui/icons-material/Info";
import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { Aircraft } from "../../screens/LabelingTool";
import { formatNumber } from "../../utils";
import { feetToMeters, squareFeetToSquareMeters } from "../../utils/math";
import { HtmlTooltip } from "../HtmlTooltip";

type Props = {
  aircraft: Aircraft;
};

export const AircraftSpecsTooltip: React.FC<Props> = ({ aircraft }) => {
  const { activeFBO } = useActiveFBOs();
  const imageSize = 150;
  const pixelSpanWingspan =
    (imageSize / Math.max(aircraft.length, aircraft.wingspan)) *
    aircraft.wingspan;
  const pixelSpanLength =
    (imageSize / Math.max(aircraft.length, aircraft.wingspan)) *
    aircraft.length;

  const wingspan = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(aircraft.wingspan), 2)} m`
    : `${formatNumber(aircraft.wingspan, 2)} ft`;
  const length = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(aircraft.length), 2)} m`
    : `${formatNumber(aircraft.length, 2)} ft`;

  const tailHeight = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(aircraft.tail_height), 2)} m`
    : `${formatNumber(aircraft.tail_height, 2)} ft`;

  const area = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(
        squareFeetToSquareMeters(aircraft.length * aircraft.wingspan),
        0
      )} m²`
    : `${formatNumber(aircraft.length * aircraft.wingspan, 1)} ft²`;

  return (
    <HtmlTooltip
      title={
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box sx={{ pt: 1, pl: 1, pr: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Typography>{area}</Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems="flex-end"
              sx={{ height: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ height: "100%" }}
              >
                <Typography>{length}</Typography>
                <Box>
                  <div
                    style={{
                      backgroundImage: `url(${aircraft.image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      width: pixelSpanWingspan,
                      height: pixelSpanLength,
                      filter: "brightness(0) invert(1)",
                      transform: aircraft.tail_wheel ? `rotate(180deg)` : ``,
                      borderLeft: !aircraft.tail_wheel ? "4px solid blue" : "",
                      borderBottom: !aircraft.tail_wheel
                        ? "4px solid blue"
                        : "",
                      borderRight: aircraft.tail_wheel ? "4px solid blue" : "",
                      borderTop: aircraft.tail_wheel ? "4px solid blue" : "",
                    }}
                  />
                  <Typography textAlign="center">{wingspan}</Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ width: "100%" }}
          >
            <Typography>H: {tailHeight}</Typography>
          </Stack>
        </Stack>
      }
    >
      <InfoIcon sx={{ color: "info.dark", fontSize: 18 }} />
    </HtmlTooltip>
  );
};
