import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useKeyPress } from "react-use";
import { rotatePoints, scalePolygon } from "../math";
import { Shape } from "../RampCanvas";
import { updateAtId } from "../utils";

type Props = {
  shapes: Shape[];
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  activeShapes: string[];
  setActiveShapes: React.Dispatch<React.SetStateAction<string[]>>;
  pixelsToFeetRatio: number;
  getActiveShapes: () => Shape[];
  undo: () => void;
  duplicateShapes: () => void;
};
export const useCanvasHotkeys = (props: Props) => {
  const {
    shapes,
    setShapes,
    activeShapes,
    setActiveShapes,
    getActiveShapes,
    pixelsToFeetRatio,
    undo,
    duplicateShapes,
  } = props;
  /**
   * Rotate the shape we're hover over when shift + <arrow> is pressed
   */
  const [isShiftPressed] = useKeyPress("Shift");
  const [isRightArrowPressed] = useKeyPress("ArrowRight");
  const [isLeftArrowPressed] = useKeyPress("ArrowLeft");
  const [isUpArrowPressed] = useKeyPress("ArrowUp");
  const [isDownArrowPressed] = useKeyPress("ArrowDown");

  useEffect(() => {
    if (isShiftPressed && (isRightArrowPressed || isLeftArrowPressed)) {
      if (activeShapes.length > 0) {
        let updatedShapes = [...shapes];

        // if all selected shapes are T shapes, we're going to "snap" them
        // to the same angle. we'll pick the angle of the first one to snap to
        // to start.
        const selectedShapes = getActiveShapes();
        if (
          (selectedShapes.every((shape) => shape.type === "Tie Down T") ||
            selectedShapes.every((shape) => shape.type === "Double T")) &&
          !selectedShapes
            .map((shape) =>
              Math.round(
                100 *
                  Math.atan2(
                    shape.points[1].y - shape.points[0].y,
                    shape.points[1].x - shape.points[0].x
                  )
              )
            )
            .every((val, i, arr) => val === arr[0])
        ) {
          const referenceAngle = Math.atan2(
            selectedShapes[0].points[1].y - selectedShapes[0].points[0].y,
            selectedShapes[0].points[1].x - selectedShapes[0].points[0].x
          );
          selectedShapes.forEach((shape) => {
            // Calculate the current angle of the shape
            const currentAngle = Math.atan2(
              shape.points[1].y - shape.points[0].y,
              shape.points[1].x - shape.points[0].x
            );

            // Calculate the angle difference to align with the reference angle
            const angleDifference = referenceAngle - currentAngle;

            const rotatedPoints = rotatePoints(
              shape.points,
              (angleDifference * 180) / Math.PI
            );

            updatedShapes = updateAtId(updatedShapes, {
              ...shape,
              points: rotatedPoints,
            });
          });
          setShapes(updatedShapes);
          return;
        }

        selectedShapes.forEach((shape) => {
          updatedShapes = updateAtId(updatedShapes, {
            ...shape,
            points: rotatePoints(
              shape.points,
              isRightArrowPressed ? 0.1 : -0.1
            ),
          });
        });
        setShapes(updatedShapes);
        return;
      }
    }
    // adjust size if up or down arrow is pressed
    if (isShiftPressed && (isUpArrowPressed || isDownArrowPressed)) {
      if (activeShapes.length > 0) {
        const selectedShapes = getActiveShapes();
        let updatedShapes = [...shapes];
        selectedShapes.forEach((shape) => {
          updatedShapes = updateAtId(updatedShapes, {
            ...shape,
            points: scalePolygon(shape.points, isUpArrowPressed ? 1.01 : 0.99),
          });
        });
        setShapes(updatedShapes);
        return;
      }
    }

    // no shift key, so we're just going to move all relevant shapes
    // in the direction of the arrow key
    if (
      isLeftArrowPressed ||
      isRightArrowPressed ||
      isDownArrowPressed ||
      isUpArrowPressed
    ) {
      const relevantShapes = getActiveShapes();
      let updatedShapes = [...shapes];
      relevantShapes.forEach((shape) => {
        const dx = isRightArrowPressed ? 1 : isLeftArrowPressed ? -1 : 0;
        const dy = isDownArrowPressed ? 1 : isUpArrowPressed ? -1 : 0;
        updatedShapes = updateAtId(updatedShapes, {
          ...shape,
          points: shape.points.map((point) => ({
            x: point.x + dx * pixelsToFeetRatio,
            y: point.y + dy * pixelsToFeetRatio,
          })),
        });
      });
      setShapes(updatedShapes);
    }
  }, [
    props,
    isShiftPressed,
    isRightArrowPressed,
    isLeftArrowPressed,
    isUpArrowPressed,
    isDownArrowPressed,
  ]);

  useHotkeys(
    "ctrl+d",
    () => {
      duplicateShapes();
    },
    [activeShapes, shapes]
  );

  useHotkeys(
    ["meta+z", "ctrl+z"],
    () => {
      undo();
    },
    [undo]
  );
};
