import { round } from "lodash";
import * as React from "react";
import { useAsync } from "react-use";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useApi } from "../../containers/ApiContainer";
import { formatCurrency, formatNumber } from "../../utils";
import { feetToMeters, squareFeetToSquareMeters } from "../../utils/math";
import { Aircraft } from "../LabelingTool";
import { Rate } from "../Settings/RevenueEstimator/RatesForm";
import { FeeCalculator2Presenter } from "./FeeCalculator2Presenter";

export const FeeCalculator2: React.FC<{
  defaultMonthlyRate?: number;
  defaultDailyRate?: number;
}> = ({ defaultMonthlyRate, defaultDailyRate }) => {
  const { postgrest } = useApi();
  const { activeFBO, setFBO } = useActiveFBOs();
  const [aircraft, setAircaft] = React.useState<Aircraft | null>(null);
  const [bufferRaw, setBuffer] = React.useState<number>(
    activeFBO?.settings?.defaultBuffer || 0
  );
  const buffer = bufferRaw || 0;
  const [monthlyRate, setMonthlyRate] = React.useState<number>(
    round(defaultMonthlyRate ?? 0, 2)
  );
  const [dailyRate, setDailyRate] = React.useState<number>(
    round(defaultDailyRate ?? 0, 2)
  );
  const [cam, setCam] = React.useState<number>(
    activeFBO?.settings?.defaultCAM || 0
  );
  const [camPerSqft, setCamPerSqft] = React.useState<number>(
    activeFBO?.settings?.defaultCAMPerSqft || 0
  );

  const { loading, value: availableRates } = useAsync(async () => {
    const { data: alternativeRates } = await postgrest
      .from("rates")
      .select("*");
    const rates: Rate[] = [];
    rates.push({
      id: "default",
      fbo_id: activeFBO.id,
      label: "Default",
      monthly_rate: activeFBO.nightly_base_rate * 30,
      nightly_rate: activeFBO.nightly_transient_rate,
      created_at: new Date().toISOString(),
    });
    rates.push(...alternativeRates);
    return rates;
  }, []);

  const formatUnit = (value: number, squared: boolean) => {
    if (activeFBO?.settings?.useMetricSystem) {
      if (squared) {
        return `${formatNumber(squareFeetToSquareMeters(value), 2)} m²`;
      }
      return formatNumber(feetToMeters(value), 2);
    }
    if (squared) {
      return `${formatNumber(value, 0)} ft²`;
    }
    return formatNumber(value, 0);
  };

  const aircraftSqftValue = aircraft ? aircraft.length * aircraft.wingspan : 0;
  const aircraftSqft = aircraft ? formatUnit(aircraftSqftValue, true) : "---";
  const aircraftSqftWithBufferValue = aircraft
    ? (2 * buffer + aircraft.length) * (2 * buffer + aircraft.wingspan)
    : 0;
  const aircraftSqftWithBuffer = aircraft
    ? formatUnit(aircraftSqftWithBufferValue, true)
    : "---";

  const baseDailyTenantDisplayValue =
    !aircraft || !dailyRate
      ? "---"
      : formatCurrency(dailyRate * aircraft.length * aircraft.wingspan, 2);
  const dailyTenantDisplayValue =
    !aircraft || !dailyRate
      ? "---"
      : formatCurrency(
          dailyRate *
            (2 * buffer + aircraft.length) *
            (2 * buffer + aircraft.wingspan),
          2
        );

  const baseMonthlyTenantValue =
    monthlyRate * aircraft?.length * aircraft?.wingspan;
  const baseMonthlyTenantDisplayValue =
    !aircraft || !monthlyRate
      ? "---"
      : formatCurrency(baseMonthlyTenantValue, 2);

  const monthlyTenantValue =
    monthlyRate *
    (2 * buffer + aircraft?.length) *
    (2 * buffer + aircraft?.wingspan);

  let monthlyTenantValueWithCAM =
    monthlyRate *
    (2 * buffer + aircraft?.length) *
    (2 * buffer + aircraft?.wingspan);

  if (cam) {
    monthlyTenantValueWithCAM += monthlyTenantValue * (cam / 100);
  } else if (camPerSqft) {
    monthlyTenantValueWithCAM +=
      camPerSqft *
      ((2 * buffer + aircraft?.length) * (2 * buffer + aircraft?.wingspan));
  }

  let dailyCamFees = 0;
  let monthlyCamFees = 0;
  if (cam) {
    dailyCamFees =
      (dailyRate *
        (2 * buffer + aircraft?.length) *
        (2 * buffer + aircraft?.wingspan) *
        cam) /
      100;
    monthlyCamFees =
      monthlyRate *
      (2 * buffer + aircraft?.length) *
      (2 * buffer + aircraft?.wingspan) *
      (cam / 100);
  } else {
    dailyCamFees =
      dailyRate *
      (2 * buffer + aircraft?.length) *
      (2 * buffer + aircraft?.wingspan) *
      camPerSqft;
    monthlyCamFees =
      camPerSqft *
      ((2 * buffer + aircraft?.length) * (2 * buffer + aircraft?.wingspan));
  }

  const monthlyTenantDisplayValue =
    !aircraft || !monthlyRate ? "---" : formatCurrency(monthlyTenantValue, 2);

  const monthlyTenantDisplayValueWithCAM =
    !aircraft || !monthlyRate
      ? "---"
      : formatCurrency(monthlyTenantValueWithCAM, 2);

  if (loading) {
    return null;
  }

  return (
    <FeeCalculator2Presenter
      aircraft={aircraft}
      buffer={bufferRaw}
      setBuffer={setBuffer}
      aircraftSqft={aircraftSqft}
      aircraftSqftWithBuffer={aircraftSqftWithBuffer}
      setAircaft={setAircaft}
      monthlyTenantValue={monthlyTenantValue}
      baseMonthlyTenantValue={baseMonthlyTenantValue}
      monthlyTenantValueWithCAM={monthlyTenantValueWithCAM}
      baseMonthlyTenantDisplayValue={baseMonthlyTenantDisplayValue}
      monthlyTenantDisplayValue={monthlyTenantDisplayValue}
      monthlyTenantDisplayValueWithCAM={monthlyTenantDisplayValueWithCAM}
      baseDailyTenantDisplayValue={baseDailyTenantDisplayValue}
      dailyTenantDisplayValue={dailyTenantDisplayValue}
      dailyCamFees={dailyCamFees}
      monthlyCamFees={monthlyCamFees}
      activeFBO={activeFBO}
      setFBO={setFBO}
      nightlyRate={dailyRate}
      cam={cam}
      setCam={setCam}
      camPerSqft={camPerSqft}
      setCamPerSqft={setCamPerSqft}
      dailyRate={dailyRate}
      setDailyRate={setDailyRate}
      monthlyRate={monthlyRate}
      setMonthlyRate={setMonthlyRate}
      availableRates={availableRates}
    />
  );
};
