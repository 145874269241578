import * as React from "react";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import draggableAircraftRegistry from "../../../screens/Hangar/HangarLayout/DraggableAircraftNew/misc/draggableAircraftRegistry";
import { Stack } from "../../../types";

type Props = {
  stack: Stack;
  setStack: (stack: Stack) => void;
};

export const useUnselect = (props: Props, deps: any[] = []) => {
  const { hangars } = useMultiHangarState();

  // problem here is that if we have multiple hangars on the screen, this is going to be hangingonto the same
  // piece of the DOM which is going to cause problems. we need to have a unique ref for each hangar
  if (hangars.length > 1) {
    useUnselectMultiHangar();
    return;
  }

  const { stack, setStack } = props;
  // This hook is responsible for unselecting an aircraft if the user taps
  // anywhere on the page except for a plane or the aircraft list
  React.useEffect(() => {
    // TODO: this should be a callback looking at hangar?
    const onBodyClick = (e) => {
      var userIsBusy = draggableAircraftRegistry.isAnyAircraftBeingInteractedWith();
      if (userIsBusy) return;

      var node = e.target;
      while (true) {
        if (!node || node == document.body) break;
        if (
          typeof node.className === "string" &&
          node.className.includes("DraggablePlane")
        ) {
          // User tapped on a plane so don't unselect anything
          return;
        }

        const dataElement = node.getAttribute("data-element");
        // User tapped on either the aircraft list or something within/related to it. in these cases
        // we want to keep the aircraft selected. this implementation is a bit hacky but it works for now.
        const elementsToIgnore = [
          "li_current_aircraft",
          "aircraft_menu",
          "notes-dialog",
          "edit_tenant",
        ];
        if (elementsToIgnore.includes(dataElement)) {
          return;
        }

        node = node.parentNode;
      }

      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => ({
          ...t,
          selected: false,
        })),
        movableObstacles: stack.movableObstacles.map((mo) => ({
          ...mo,
          selected: false,
        })),
      });
    };

    document.body.addEventListener("mouseup", onBodyClick, {
      // We need this so that this listener fires before the drag event
      // has ended.
      capture: true,
    });
    return () => {
      document.body.removeEventListener("mouseup", onBodyClick, {
        capture: true,
      });
    };
  }, deps);
};

export const useUnselectMultiHangar = () => {
  const { hangars, setHangars } = useMultiHangarState();
  // This hook is responsible for unselecting an aircraft if the user taps
  // anywhere on the page except for a plane or the aircraft list
  React.useEffect(() => {
    // TODO: this should be a callback looking at hangar?
    const onBodyClick = (e) => {
      var userIsBusy = draggableAircraftRegistry.isAnyAircraftBeingInteractedWith();
      if (userIsBusy) return;

      var node = e.target;
      while (true) {
        if (!node || node == document.body) break;
        if (
          typeof node.className === "string" &&
          node.className.includes("DraggablePlane")
        ) {
          // User tapped on a plane so don't unselect anything
          return;
        }

        // User tapped on the aircraft list
        // if (node == aircraftListRef.current) {
        //   return;
        // }

        node = node.parentNode;
      }

      setHangars(
        hangars.map((hangar) => ({
          ...hangar,
          stack: {
            ...hangar.stack,
            tenants: hangar.stack.tenants.map((t) => ({
              ...t,
              selected: false,
            })),
            movableObstacles: hangar.stack.movableObstacles.map((mo) => ({
              ...mo,
              selected: false,
            })),
          },
        }))
      );
    };

    document.body.addEventListener("mouseup", onBodyClick, {
      // We need this so that this listener fires before the drag event
      // has ended.
      capture: true,
    });
    return () => {
      document.body.removeEventListener("mouseup", onBodyClick, {
        capture: true,
      });
    };
  }, [hangars, setHangars]);
};
